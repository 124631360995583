import { template as template_c31052a1e8e84081b8f65712ffd167cd } from "@ember/template-compiler";
const FKControlMenuContainer = template_c31052a1e8e84081b8f65712ffd167cd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
