import { template as template_a9cb8741bb6c45e7ad886344da7133e1 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_a9cb8741bb6c45e7ad886344da7133e1(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
