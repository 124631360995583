import { template as template_79a68d3f3bfb4231af0b45f61ab844f9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_79a68d3f3bfb4231af0b45f61ab844f9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
