import { template as template_cba3e0f5a682484ab40db2ebacf98718 } from "@ember/template-compiler";
const WelcomeHeader = template_cba3e0f5a682484ab40db2ebacf98718(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
